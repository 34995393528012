import {Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {SettingsService} from '../../services/settings.service';

@Component({
  standalone: false,
  // tslint:disable-next-line:component-selector
  selector: '[app-tree-view]',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})
export class TreeViewComponent implements OnInit, OnChanges {

  public httpOptions = { };
  public modelValue;
  public dataSource = [{ }];


  @Output() modelChange: EventEmitter<any>;
  @Output() callback: EventEmitter<any> = new EventEmitter();

  @Input()
  get model() {
    return this.modelValue;
  }
  set model(value) {
    this.ngOnChanges(value);
  }

  ngOnChanges(value) {
    if ( typeof value !== 'string' ) { return; }
    this.modelValue = value;
  }

  changeModel(item): void {
    this.modelValue = item.id;
    this.modelChange.emit(this.modelValue);
  }

  @Input()
  get data() {
    return this.dataSource;
  }
  set data(value) {
    this.dataSource = value;
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.modelChange = new EventEmitter();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        token: settings.sessionId
      })
    };
  }


  // ENDE MODEL



  ngOnInit() {
  }

  handleClickEvent(item) {
  if (item.selectable === 1) {
    this.changeModel(item);
    this.callSearch();
  } else {
    return;
    }
  }

  toggle(element) {
    element.open = !element.open;
  }


  getProductId(item) {
    this.modelValue = item.id;
    this.modelChange.emit(this.modelValue);
  }

  callSearch() {
    this.callback.emit();
  }
}
