import { formatNumber } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import {SettingsService} from "../../services/settings.service";
import {ModalService} from "../../_modal";
import {CalendarViewComponent} from "../calendar-view/calendar-view.component";
import {fromEvent, Observable, Subscription} from "rxjs";
import {CdkDragEnd} from '@angular/cdk/drag-drop';
import {NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
  standalone: false,
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css','../calendar-view/color.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CalendarComponent,
    multi: true
  }]
})
export class CalendarComponent implements OnInit, OnDestroy {
  public OverlayActive = false;
  public overlayId: string;
  public toolTipContent: any;
  public httpOptions = { };
  public data: any[] = [];
  public filteredData: any[];
  public viewDateForm = false;
  public formData: any;
  public calendarGranted: [ { rights: any, name: any, solo: number,color: any,visible: any,id: any,fk_user_owner: any}];
  public dataReminder: any;
  public viewAllCalendarGranted = false;
  public processDataForNewDate: {tid,id,titel,recurring_id} = null;
  public dateToDelete: any;
  public timeBoxData: any;
  public currentSelectedSettingsTab = 1;
  public calendarReleased: any;
  public calenderNotReleased: any;
  public newCalenderToRelease = { id: '-1', right: '1' };
  public newTimebox =  { day:'1', start_time:'', end_time:'', text:'' };
  public dataReminderSelectable: any;
  public editorWarningString: string;
  public max_last_changed: string = '0';
  private updateIntervallRepeatTime: number  = 10000;
  private updateIntervalHandler: any;
  public resizeObservable$: Observable<Event>
  public resizeSubscription$: Subscription
  public markedProcessData: any = [];
  public dayNames = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  public monthNames = [ "Januar", "Februar", "März", "April", "Mai", "Juni", "July", "August", "September", "Oktober", "November", "Dezember" ];

  public dropdownOptionsMonthOrYear1: string;
  public dropdownOptionsMonthOrYear2: string;
  public dropdownOptionsMonthOrYear3: string;

  @ViewChild('sideCalendar1' , {static: false}) sideCalendar1: CalendarViewComponent ;
  @ViewChild('sideCalendar2' , {static: false}) sideCalendar2: CalendarViewComponent ;
  @ViewChild('mainCalendar'  , {static: false}) mainCalendar:  CalendarViewComponent ;
  viewMode: number = 0;


  @HostListener('document:keydown ', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      // ESC schließt den Termin Editor wenn offen. Sonst den Kalender.
      if(this.viewDateForm) { this.closeDateEditForm() } else { this.deactivateOverlay(); }
    } else  if (event.key === 'Delete') {
      // Löschen wenn Termin Markiert
      if(this.mainCalendar == undefined || this.mainCalendar.currentSelectedDateItem == undefined || this.mainCalendar.currentSelectedDateItem == null) { return; }
      this.dateToDelete = this.mainCalendar.currentSelectedDateItem;
      this.message.open('deleteDate');
    }
  }

  constructor(public http: HttpClient,
              public settings: SettingsService,
              public message: ModalService,
              public router: Router,
              public ref: ChangeDetectorRef) {
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.overlayId = + Date.now() + '_' + Math.floor((Math.random() * 9999) + 1000);
    this.settings.calendar = this;
  }

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( event => this.resizeAction(event))
    this.loadMarkedProcess();
    this.initCalendarGranted();
    this.loadRemindType();
    this.loadDates();
    this.loadTimebox(true);
    setTimeout(() => { this.initFormData(); }, 200);
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe()
  }

  private startUpdateIntervall() {
      this.updateIntervalHandler = setInterval(() => {
        this.loadDates();
      }, this.updateIntervallRepeatTime);
  }

  private stopUpdateIntervall() {
    clearInterval(this.updateIntervalHandler);
  }


  private resizeAction(_event: Event) {
    this.sideCalendar1.changeMode(11);
    this.sideCalendar2.changeMode(11);
  }

  public initCalendarGranted() {
    this.http.post<{ status: any, message: any, data: [{ rights: any; name: any; solo: number; color: any; visible: any; id: any; fk_user_owner: any; }] }>(this.settings.restBaseUrl + 'calendar/granted', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.calendarGranted = data.data;
          this.calendarGranted.unshift({ name:'Mein Kalender',solo:0,color:'0',visible:'1',id:'0',fk_user_owner:this.settings.data.userId, rights:2 });
          const calendarCurrentSolo = this.settings.get('calendarCurrentSolo');
          if(calendarCurrentSolo > 0) {
            for(let i = 0; i < this.calendarGranted.length;i++) {
              if(this.calendarGranted[i].fk_user_owner == calendarCurrentSolo) {
                this.calendarGranted[i].solo = 1;
                break;
              }
            }
          }
        }
    );
  }

  public initCalendarReleased() {
    this.http.post<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/released', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.calendarReleased = data.data;
        }
    );
  }

  public initCalendarNotReleased() {
    this.http.post<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/released', {'invert':1}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.calenderNotReleased = data.data;
        }
    );
  }

  public filterDate() {
    // is show all
    if(this.viewAllCalendarGranted==true) {
      this.filteredData = this.data;
      return;
    }
    // Solo Ansicht eines Kalenders
    const soloCalendar = this.calendarGranted.filter(item => item.solo == 1);
    if( soloCalendar.length > 0) {
      this.filteredData = this.data.filter(item => item.fk_user_owner == soloCalendar[0].fk_user_owner);
      return;
    }
    // Nur die sichtbaren Kalender Termine filtern
    let visibleCalendar = [];
    this.calendarGranted.forEach(  (calendar) => {
      if(calendar.visible==1) {
        visibleCalendar.push(calendar.fk_user_owner);
      }
    })
    this.filteredData = this.data.filter(item => visibleCalendar.includes(item.fk_user_owner));
  }
  viewDate(data: any) {
  }

  newDate(timeForNewDate: { day: any; month: any; year: string; hour: any; minute: any; tid: any; name:any }) {
    this.initFormData();
    this.buildReminderSelectable();
    if( this.processDataForNewDate != null) {
      this.formData.tid = this.processDataForNewDate.tid;
      this.formData.name = this.processDataForNewDate.titel;
      if(this.processDataForNewDate.recurring_id>0) { this.formData.hide_process = 1;}
    }
    if(timeForNewDate.tid !='' && timeForNewDate.name!='') {
      this.formData.tid = timeForNewDate.tid;
      this.formData.name = timeForNewDate.name;
    }

    this.formData.startDate = this.formData.endDate  = formatNumber(Number(timeForNewDate.day), 'de-DE', '2.0') + '.' + formatNumber(Number(timeForNewDate.month), 'de-DE', '2.0') + '.' + timeForNewDate.year;
    this.formData.timeStart = formatNumber(Number(timeForNewDate.hour)  , 'de-DE', '2.0') + ':' + formatNumber(Number(timeForNewDate.minute), 'de-DE', '2.0');
    // :75 verhindern
    let endHour:number = Number(timeForNewDate.hour);
    let endMinute:number = Number(timeForNewDate.minute)+30;
    if(endMinute >= 60) { endMinute = endMinute - 60; endHour++;}
    this.formData.timeEnd   = formatNumber(endHour, 'de-DE', '2.0') + ':' + formatNumber(endMinute, 'de-DE', '2.0');
    this.viewDateForm = true;
    setTimeout(() => { document.getElementById('calendarDateFormName').focus(); }, 50);
  }

  editDate(date: { [x: string]: any; }) {
    // Ermitteln, ob nur Lesen Recht, dann Abbrechen
    if(!this.mainCalendar.checkCalendarRight(date)) { alert('Sie haben nur Lesezugriff auf diesen Kalender.'); return; }
    this.initFormData();
    this.formData = date;
    this.formData.init_start_time = this.formData.start_time;
    this.formData.newReminder = '0';
    let reminder = [];
    if(this.formData.reminderArray == undefined) { this.formData.reminderArray = []; }
    if(this.formData.reminder != undefined && this.formData.reminderArray.length < 1 ) {
      // Erinnerungsdaten wandeln und in separate Array schreiben
        let rowData: string[];
        let reminderData = this.formData.reminder.split(',');
        reminderData.forEach( (row: string) => {
          rowData = row.split(';');
          reminder.push( { id:rowData[0],name: this.getReminderName(rowData[1]),fk_date_remind_type:rowData[1],sms:(rowData[2] == '1') });
        })
      this.formData.reminderArray = reminder;
    }
    this.buildReminderSelectable();
    this.viewDateForm = true;
  }

  buildReminderSelectable() {
    if(this.formData.reminderArray && this.formData.reminderArray.length<1) {
      this.dataReminderSelectable = this.dataReminder; return;
    }
      this.dataReminderSelectable  = this.dataReminder.filter( (possibleReminder: any)=>{
        return  this.formData.reminderArray.filter( (selectedReminder: any) =>{
          return selectedReminder.name == possibleReminder.name;
        }).length == 0
      });
  }


  public createDateFromProcess(tid: string) {
    this.http.get<any>(this.settings.restBaseUrl + 'process/calendar/' + tid, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.processDataForNewDate = data;
          this.toggleOverlay();
        }
    );
  }

  toggleOverlay() {
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }

  activateOverlay(): void {
    this.loadMarkedProcess();
    this.OverlayActive = true;
    setTimeout(() => {
      this.filterDate();
      setTimeout(() => {
        this.mainCalendar.recalculateDatePositions();
        this.viewMode = this.mainCalendar.getViewMode();
        //this.startUpdateIntervall();
      }, 50);
    }, 50);
  }

  deactivateOverlay(): void {
    this.OverlayActive = false;
    this.message.close('calenderSettings');
    this.processDataForNewDate=null;
    this.stopUpdateIntervall();
  }

  loadDates() {
    if( this.mainCalendar && this.mainCalendar.isInUserAction()) { return; } // Wenn gerade im Kalender view etwas bewegt wird, Dann kein Nachladen von Terminen
    this.http.post<{status: any, message: any, data: any[], max_last_changed: any, count: any}>(this.settings.restBaseUrl + 'calendar', { max_last_changed: this.max_last_changed}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if(this.data && this.data.length < 1) {
            // Wenn noch keine Termine vorhanden dann einfach schreiben
            this.data = data.data;
          } else {
            // Wenn keine Updates dann beenden
            if(data.count == 0) { return; }
            // Sonst aktualisieren und neue einfügen
            // Erst vorhandene aktualisieren
            for(let n = 0; n < this.data.length; n++) {
              for(let i = 0; i < data.count; i++) {
                if(data.data[i].id == this.data[n].id) {
                  // Wenn new dann entfernen und neu einfügen
                  let checkForReload = false;
                  if(data.data[i].new==1) {
                    this.data.splice(n, 1);
                    data.data[i].updated = 1;
                    this.data = [data.data[i]].concat(this.data);
                    checkForReload=true;
                  } else {
                    this.data[n] = data.data[i];
                    data.data[i].updated = 1;
                    checkForReload=true;
                  }
                }
              }
            }
            // Dann den Rest anhängen
            // Restliche am Anfang der Liste voranstellen
            this.data = data.data.filter((item) => item.updated != 1).concat(this.data);
            this.filterDate();
            setTimeout(() => {
              this.mainCalendar.recalculateDatePositions();
            }, 100);
          }
          if(data.max_last_changed) {
            this.max_last_changed = data.max_last_changed;
          }
        }
    );
  }

  saveDateTime(date: any) {
    this.http.put<any>(this.settings.restBaseUrl + 'calendar/time', JSON.stringify(date) , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {

        }
    );
  }

  moveSideCalendar(number: number) {
    this.sideCalendar1.changeViewArea(number);
    this.sideCalendar2.changeViewArea(number);
    this.mainCalendar.updateTimeRangeSend();
  }

  changeMainCalendarTo(date: any) {
    // Side Kalender Klick ändert den Hauptkalender
    this.mainCalendar.setDate(date.day,date.month,date.year)
  }

  saveDate() {
    // Titel prüfen
    if(this.formData.name == '') {
      this.editorWarningString = "Sie müssen einen Titel angeben um den Termin zu Speichern!";
      this.message.open('editorWarningCalendar');
      return;
    }
    // todo: Zeit prüfen
    // todo: Enddatum vor Startdatum
    // todo: Endzeit vor Startzeit am gleichen Tag


    this.http.put<any>(this.settings.restBaseUrl + 'calendar', JSON.stringify(this.formData) , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.processDataForNewDate = null;
          if(data.data != undefined) {
            if(this.formData.id < 1) {
              // Neuer Eintrag
              for(let i= this.data.length-1;i>-1;i--) {
                if(this.data[i].start_time < data.data[0].start_time) {
                  this.data.splice(i+1 , 0 , data.data[0]);
                  break;
                }
              }
              this.filterDate();
            } else {
              // Bearbeitet, daher update des Termins
              for(let i= this.data.length-1;i>-1;i--) {
                if(this.data[i].id == data.data[0].id) {
                  this.data[i] = data.data[0];
                  break;
                }
              }
              this.filterDate();
            }
            setTimeout(() => {
              this.mainCalendar.recalculateDatePositions();
              this.closeDateEditForm();
            }, 100);
          }
        }
    );
  }

  formAddReminder() {
    this.formData.reminderArray.push( { id:-1,name: this.getReminderName(this.formData.newReminder),fk_date_remind_type:this.formData.newReminder,sms:false } );
    this.buildReminderSelectable();
    setTimeout(() => {
      this.formData.newReminder = '0';
    }, 50);
  }

  public initFormData() {
    this.formData = {id:'',tid:'',fk_user_owner:this.settings.data.userId ,name:'',text:'', full_day: '', hide_process: '',
                      recurringMode:'0' , recurringCount:1 , recurringWeekday: { mo:0, di:0, mi:0, do:0, fr:0, sa:0, so:0},recurring_month_or_year_mode:1, recurringEndType:0, recurringEndDate:'', recurringEndCount:1, recurringText:'', recurringChange:1,
                      reminder:[], reminderArray:[], newReminder:0,startDate:'',startTime:'',endDate:'',endTime:''};
  }

  public loadRemindType() {
    if(Array.isArray(this.dataReminder) && this.dataReminder.length>0) { return; }
    this.http.post<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/remind/type', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dataReminder = data.data;
        }
    );
  }

  closeDateEditForm() {
    this.viewDateForm = false;
    this.formData = null;
  }

  changeViewAllCalendarGranted() {
    this.clearCalendarGrantedToSolo();
    this.viewAllCalendarGranted = !this.viewAllCalendarGranted;
    this.filterDate();
    setTimeout(() => {
      this.mainCalendar.recalculateDatePositions();
    }, 50);
  }

  changCalendarGrantedVisibility(calendar: { visible: string,fk_user_owner: string }) {
    this.clearCalendarGrantedToSolo();
    this.settings.set('calendarCurrentSolo', 0,1);
    if( calendar.visible == '1') { calendar.visible = '0'; } else { calendar.visible = '1'; }
    // Sichtbarkeit in der DB Aktualisieren
    this.http.put<any>(this.settings.restBaseUrl + 'calendar/visible', {fk_user_owner:calendar.fk_user_owner,visible:calendar.visible}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
        }
    );
    this.filterDate();
    setTimeout(() => {
      this.mainCalendar.recalculateDatePositions();
    }, 50);
  }

  setCalendarGrantedToSolo(calendar: { solo: number, fk_user_owner:string }) {
    this.viewAllCalendarGranted = false;
    if(calendar.solo != 1) {
      this.clearCalendarGrantedToSolo();
      calendar.solo = 1;
      // Persistent in der Session Speichern
      this.settings.set('calendarCurrentSolo', calendar.fk_user_owner,1);
    } else {
      this.clearCalendarGrantedToSolo();
      this.settings.set('calendarCurrentSolo', 0,1);
    }
    this.filterDate();
    setTimeout(() => {
      this.mainCalendar.recalculateDatePositions();
    }, 50);

  }

  clearCalendarGrantedToSolo() {
    this.calendarGranted.forEach( (calendar) => {
      calendar.solo = 0;
    })
  }

  cancelDeleteDate() {
    this.dateToDelete = null;
    this.message.close('deleteDate');
  }

  checkDeleteDate(date: any) {
    this.dateToDelete = date;
    this.message.open('deleteDate');
  }

  deleteDate() {
    this.http.delete<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/' + this.dateToDelete.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          // Löschendes des Termins aus der data Liste und filterliste neu aufbauen
          for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].id === this.dateToDelete.id ) {
              this.data.splice(i, 1);
            }}
          this.filterDate();
          setTimeout(() => {
            this.mainCalendar.recalculateDatePositions();
          }, 50);
          this.dateToDelete = null;
        }
    );
    this.message.close('deleteDate');
  }

  test(timeStart: any) {
    alert(timeStart);
  }

  showCalendarSettings() {
    this.initCalendarReleased();
    this.initCalendarNotReleased();
    this.message.open('calenderSettings');
  }

  changeSettingsTab(number: number) {
    this.currentSelectedSettingsTab = number;
  }

  deleteGrantedCalendar(calendar: { name: any; solo: any; color: any; visible: any; id: string; fk_user_owner: any }) {
    this.http.delete<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/granted/' + calendar.id  ,this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          // Löschen des Termins aus der data Liste und filterliste neu aufbauen
          for (let i = 0; i < this.calendarGranted.length; i++) {
            if (this.calendarGranted[i].id === calendar.id ) {
              this.calendarGranted.splice(i, 1);
            }}
        }
    );
  }

  deleteReleasedCalendar(calendar: any) {
    this.http.delete<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/released/' + calendar.id  ,this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          // Löschen des Termins aus der data Liste und filterliste neu aufbauen
          for (let i = 0; i < this.calendarReleased.length; i++) {
            if (this.calendarReleased[i].id === calendar.id ) {
              this.calendarReleased.splice(i, 1);
              this.initCalendarNotReleased();
            }}
        }
    );
  }

  addToCalendarReleased() {
    if(this.newCalenderToRelease.id == '-1') {
      alert('Sie müssen einen Benutzer aus der Liste auswählen um diesem Ihren Kalender freizugeben!');
      return;
    }
    this.http.put<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/released/' + this.newCalenderToRelease.id + '/' + this.newCalenderToRelease.right, {} ,this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // Löschen des Termins aus der data Liste und filterliste neu aufbauen
          let newCalendarReleaseName = '';
          for (let i = 0; i < this.calenderNotReleased.length; i++) {
            if (this.calenderNotReleased[i].id === this.newCalenderToRelease.id ) {
              newCalendarReleaseName = this.calenderNotReleased[i].name;
              this.calenderNotReleased.splice(i, 1);
            }}
            this.calendarReleased.push( { id:data.message, name:newCalendarReleaseName, color:0, rights: this.newCalenderToRelease.right } );
            this.newCalenderToRelease.id = '-1';
            this.newCalenderToRelease.right = '1';
        }
    );
  }

  timeRangeSelectionChanged(newRange: any) {
    this.sideCalendar1.setRangeMarking(newRange);
    this.sideCalendar2.setRangeMarking(newRange);
  }

  getReminderName(id: string) {
    let name = '';
    for(let i= 0; i < this.dataReminder.length;i++) {
      if( this.dataReminder[i].id == id ) { name = this.dataReminder[i].name; break; }
    }
    return name;
  }

  removeReminder(x: any) {
    // Erinnerung entfernen
    this.http.delete<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/remind/' + x.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          this.formData.reminderArray.splice(this.formData.reminderArray.indexOf(x), 1);
          this.buildReminderSelectable();
        }
    );

  }

  private loadTimebox(force: boolean) {
    if(Array.isArray(this.timeBoxData) && this.timeBoxData.length>0 && !force) { return; }
    this.http.post<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/timeBox', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.timeBoxData = data.data;
        }
    );
  }

  public viewProcess(tid: any) {
    this.router.navigate(['/process/' + tid + '/view/6/1']);
    this.deactivateOverlay();
  }


  dropDateOnSideCalendar($event: { month: string; year: string; day: string }) {
    if(this.mainCalendar.currentSelectedDateItem==undefined) { return; }

    // wenn source

    // reset date move

    // move date to target day

    // open main calendar on target day/week
  }

  swapRecurringWeekday(day: string) {
    this.formData.recurringWeekday ={mo:0,di:0,mi:0,do:0,fr:0,sa:0,so:0};
    this.formData.recurringWeekday[day] = 1;
    // Startdatum an den Tag anpassen
    console.log('changeDate');
    const splitDate = this.formData.startDate.split('.');
    console.log(splitDate);
    let currentDate =  new Date( splitDate[2], splitDate[1] - 1, splitDate[0], 2, 0 , 0 );
    console.log(currentDate.toDateString());
    let currentWeekday = currentDate.getDay();
    if(currentWeekday==0) {currentWeekday=6} else {currentWeekday--}
    console.log(currentWeekday);

    let dayNum;
    switch (day) { case 'mo':dayNum = 1;break;case 'di':dayNum = 2;break;case 'mi':dayNum = 3;break;case 'do':dayNum = 4;break;case 'fr':dayNum = 5;break;case 'sa':dayNum = 6;break;case 'so':dayNum = 7;}
    console.log(dayNum);
    let divDays =  dayNum - (currentWeekday+1);
    console.log(divDays);
    currentDate.setDate(currentDate.getDate() + divDays);
    console.log(currentDate.toDateString());
    this.formData.startDate =  formatNumber(currentDate.getDate(),'de-DE', '2.0') + '.' + formatNumber(currentDate.getMonth()+1,'de-DE', '2.0') + '.' + currentDate.getFullYear();
    this.generateRecurringText();
  }

  generateRecurringText() {
    let newText = ''
    switch(this.formData.recurringMode) {
      case '0':
        // Keine Wiederholung
        newText = 'Nicht wiederholen';
        break;
      case '1':
        // Tage
        if(this.formData.recurringCount==1) { newText = 'Findet jeden Tag statt'; }
        else { newText = 'Findet alle ' + this.formData.recurringCount +' Tage statt'; }
        break;
      case '2':
        // Wochen
        newText = 'Findet ';
        if(this.formData.recurringCount>1) {         newText += 'alle ' + this.formData.recurringCount + ' Wochen ' ; }
        newText += 'am ';
        let cw = this.formData.recurringWeekday;
        let dayCount : {max:number, current:number} = { current:1, max:Number(cw.mo) + Number(cw.di) + Number(cw.mi) + Number(cw.do) + Number(cw.fr) + Number(cw.sa) + Number(cw.so), };
        // Wenn kein Tag vorbelegt, dann automatisch den Wochentag des Startdatums setzen
        if(dayCount.max == 0) {
          const splitDate = this.formData.startDate.split('.');
          cw[new Date( splitDate[2], splitDate[1], splitDate[0] ).toLocaleString('de-DE', {weekday: 'short'}).toLowerCase()]=1;
        }
        if(cw.mo) { newText += 'Montag'     + this.addDaySeparator(dayCount); }
        if(cw.di) { newText += 'Dienstag'   + this.addDaySeparator(dayCount); }
        if(cw.mi) { newText += 'Mittwoch'   + this.addDaySeparator(dayCount); }
        if(cw.do) { newText += 'Donnerstag' + this.addDaySeparator(dayCount); }
        if(cw.fr) { newText += 'Freitag'    + this.addDaySeparator(dayCount); }
        if(cw.sa) { newText += 'Samstag'    + this.addDaySeparator(dayCount); }
        if(cw.so) { newText += 'Sonntag'    + this.addDaySeparator(dayCount); }
        newText += ' statt ';
        break;
      case '3':
        // Monate

        break;
      case '4':
        // Jahr

        break;
    }
    this.formData.recurringText = newText;
  }

  addDaySeparator(dayCount: {max:number, current:number}) {
    if( dayCount.max < 2 ) { return ''; }
    if( dayCount.current == dayCount.max ) { return ' '; }
    if( dayCount.current == dayCount.max-1 ) { dayCount.current++; return ' und '} else { dayCount.current++; return ', '}
  }

  recurringFormStartDateChanged() {
    if(this.formData.recurringMode==2) {
      this.updateRecurringWeekday();
    } else if(this.formData.recurringMode==2 || this.formData.recurringMode==3) {
      this.updateRecurringMonthOrYearDropdown();
    }
    this.generateRecurringText();
  }

  changeRecurringMode() {
    // Wenn mode == 2 Woche. Dann setze Wochentag auf den des Startdatums
    if(this.formData.recurringMode==2) {
      this.updateRecurringWeekday();
    } else if(this.formData.recurringMode==3 || this.formData.recurringMode==4) {
      console.log('3 oder 4');
      this.updateRecurringMonthOrYearDropdown();
    }
    this.generateRecurringText();
    this.message.open('editorRecurring');
  }

  private updateRecurringWeekday() {
    this.formData.recurringWeekday ={mo:0,di:0,mi:0,do:0,fr:0,sa:0,so:0};
    let splitDate = this.formData.startDate.split('.');
    let weekday = new Date( splitDate[2], splitDate[1] - 1, splitDate[0], 2, 0 , 0 ).getDay();
    switch (weekday) {
      case 0:
        this.formData.recurringWeekday.so = 1;
        break;
      case 1:
        this.formData.recurringWeekday.mo = 1;
        break;
      case 2:
        this.formData.recurringWeekday.di = 1;
        break;
      case 3:
        this.formData.recurringWeekday.mi = 1;
        break;
      case 4:
        this.formData.recurringWeekday.do = 1;
        break;
      case 5:
        this.formData.recurringWeekday.fr = 1;
        break;
      case 6:
        this.formData.recurringWeekday.sa = 1;
    }
  }
  
  private updateRecurringMonthOrYearDropdown() {
    console.log('updateRecurringMonthOrYearDropdown >' + this.formData.recurringMode);
    if(this.formData.recurringMode==3) {
      // Monat Dropdown Elemente aus dem Startdatum erzeugen
      let splitDate = this.formData.startDate.split('.');
      let selectedStartDate = new Date( splitDate[2], splitDate[1] - 1, splitDate[0], 2, 0 , 0 )
      let weekday= selectedStartDate.getDay();

      this.dropdownOptionsMonthOrYear1 = 'Am ' + parseInt(splitDate[0],10) +'. des Monats';
      this.dropdownOptionsMonthOrYear2 = 'Am ' + this.getWeekdayCountInMonth(selectedStartDate) +'. ' + this.dayNames[weekday] + ' des Monates';
      this.dropdownOptionsMonthOrYear3 = 'Am letzten Tag des Monats'
    } else  if(this.formData.recurringMode==4) {
      // Jahr Dropdown Elemente aus dem Startdatum erzeugen
      let splitDate = this.formData.startDate.split('.');
      let selectedStartDate = new Date( splitDate[2], splitDate[1] - 1, splitDate[0], 2, 0 , 0 )
      let weekday= selectedStartDate.getDay();

      this.dropdownOptionsMonthOrYear1 = 'Am ' + parseInt(splitDate[0],10) +'. ' + this.monthNames[parseInt(splitDate[1],10)]  + ' ';
      this.dropdownOptionsMonthOrYear2 = 'Am ' + this.getWeekdayCountInMonth(selectedStartDate) +'. ' + this.dayNames[weekday] + ' im ' + this.monthNames[parseInt(splitDate[1],10)] + ' ';
      this.dropdownOptionsMonthOrYear3 = 'Am letzten Tag im ' + this.monthNames[parseInt(splitDate[1],10)] + ' des Jahres'
    }

  }

  private getWeekdayCountInMonth(date) {
    // Ermittelt der wievielte Wochentag das Datum im Monat ist. ZumBeispiel dritter Montag im aktuellen Monat
    let countUpDate = new Date(date.getTime());
    let weekday = date.getDay();
    let dayOfMonth = date.getDate()
    let countUp = 0;
    // Die Tage des Monates durchgehen
    for(let i=1;i < 32;i++) {
      countUpDate.setDate(i);
      if(weekday == countUpDate.getDay()) {
        countUp++; // Wenn der aktuelle Tag == dem gesuchten Wochentag ist dann hochzählen
      }
      if(i==dayOfMonth) {
        break; // Wenn der Tag im Monat erreicht ist, dann abbrechen
      }
    }
    return countUp;
  }

  timeStartChange() {
      // Anpassung der Endzeit bei aktualisierung der Startzeit.
      const splitDate = this.formData.startDate.split('.');
      const splitTime = this.formData.timeStart.split(':');
      const newTimestampStart = new Date( splitDate[2], splitDate[1] - 1, splitDate[0], splitTime[0], splitTime[1] , 0 ).getTime() / 1000 ;
      // Start Zeit hat sich geändert
      if(this.formData.init_start_time != newTimestampStart) {
        // End timestamp ermitteln und die Differez einberechnen
        const timeDiff = newTimestampStart - this.formData.init_start_time ;
        const splitDateEnd = this.formData.startDate.split('.');
        const splitTimeEnd = this.formData.timeEnd.split(':');
        const timestampEnd = new Date( splitDateEnd[2], splitDateEnd[1] - 1, splitDateEnd[0], splitTimeEnd[0], splitTimeEnd[1] , 0 ).getTime() / 1000 ;
        const newTimestampEnd = timestampEnd + timeDiff;
        // Endzeit verändern
        this.formData.end_time = newTimestampEnd;
        // Endzeit Form verändern
        const dateTimeEnd = new Date(newTimestampEnd * 1000);
        // Endzeit String setzen
        this.formData.timeEnd = formatNumber(dateTimeEnd.getHours(), 'de-DE', '2.0') + ':' + formatNumber(dateTimeEnd.getMinutes(), 'de-DE', '2.0');
        // Die neue Startzeit wieder merken, falls erneutes Anpassen erfolgt
        this.formData.init_start_time = newTimestampStart ;
      }
  }

  timeEndChange() {
    //todo: Prüfen ob Endzeit vor Startzeit

  }

  addTimebox() {
    // Alles ausgefüllt?
    // Send to timebox put
    this.http.put<any>(this.settings.restBaseUrl + 'calendar/timeBox', JSON.stringify(this.newTimebox) , this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          this.loadTimebox(true);
        }
    );
  }

  deleteTimebox(timebox: any) {
    // Delete TImebox
    this.http.delete<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/timeBox/' + timebox.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          this.loadTimebox(true);
        }
    );
  }

  private loadMarkedProcess() {
    this.http.post<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'process', { list:6, markedFilter:true }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            this.markedProcessData = data.data;
        }
    );

  }

  resetRecurringMode() {
    if(this.formData.recurringChange==0) {
      // Reset wiederholung
      this.formData.recurringChange = 1;
    } else if(this.formData.recurringChange==2) {
      // Wiederholung bearbeiten

      setTimeout(() => {
            this.formData.recurringChange = 1;
          }, 200 );
          this.message.open('editorRecurring');
    }
  }

  changeViewMode(mode: number) {
    this.viewMode = mode;
    this.mainCalendar.changeViewMode(mode);
  }

  public dottest(event: any) {
    console.log(event);
  }
}


