import {Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../../services/settings.service';

@Component({
  standalone: false,
  // tslint:disable-next-line:component-selector
  selector: '[app-core-right-modul-user-right]',
  templateUrl: './core-right-modul-user-right.component.html',
  styleUrls: ['./core-right-modul-user-right.component.css']
})
export class CoreRightModulUserRightComponent implements OnInit {


  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');


  public overlayId: string;
  public httpOptions = {};
  public userRights = [];
  public userRightsDescription = [];
  public userRightsDescriptionTitle;
  public modeValue;
  public curmod = '1';
  // MODEL
  public modelValue = '';
  @Output() modelChange: EventEmitter<string>;

  @Input()
  get model() {
    return this.modelValue;
  }
  set model(value: string) {
    this.ngOnChanges(value);
  }

  @Input()
  get mode() {
    return this.modeValue;
  }
  set mode(value: string) {
    if ( typeof value !== 'string' ) { return; }
    this.modeValue = value;
  }

  ngOnChanges(value) {
    if ( typeof value !== 'string' ) { return; }
    this.modelValue = value;
  }

  changeMainGroup(group, groupId, user): void {

    if (group != null && group !== '0') {
      if (group === groupId) {
        this.http.put<any[]>(
          this.settings.restBaseUrl + 'document/rightgroups/favorite', {group1: group, groupId1: '0', userId: user }, this.httpOptions)
          .pipe(map(data => data)).subscribe(
              data => { this.getUserRights();
              }); }
      if (group !== groupId) { let changeGroup= window.confirm('Dem User ist bereits eine Hauptgruppe zugewiesen. Soll diese ersetzt werden?');
        if (changeGroup) {
          this.http.put<any[]>(
              this.settings.restBaseUrl + 'document/rightgroups/favorite', {group1: group, groupId1: groupId, userId: user }, this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => { this.getUserRights();
              });
        }
      }
    }
    if (group === null || group === '0') {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'document/rightgroups/favorite', {group1: group, groupId1: groupId, userId: user }, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            alert('Hauptgruppe gesetzt');
            this.getUserRights();
          });
    }
  }

  constructor(public http: HttpClient, public settings: SettingsService) {
    this.modelChange = new EventEmitter();
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
  }

  changeModel(value): void {
    this.modelValue = value;
    this.modelChange.emit(this.modelValue);
  }
  // ENDE MODEL



  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    this.getUserRights();
  }

  getUserRights() {
    if (this.modeValue === 'document') {
      this.http.post<any[]>(this.settings.restBaseUrl + 'user/documentRight/' + this.modelValue, {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.userRights = data;
          }
      );
    } else {
      this.http.post<any[]>(this.settings.restBaseUrl + 'user/right/' + this.modelValue, {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.userRights = data;
          }
      );
    }
  }

  getUserRightsDescription(item) {
    if (this.modeValue === 'document') {
      this.http.post<any[]>(this.settings.restBaseUrl + 'user/documentRight/description/' + item.id, {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.userRightsDescriptionTitle = item.gruppe;
              this.userRightsDescription = data;
            }, 100);
          });
    } else {
      this.http.post<any[]>(this.settings.restBaseUrl + 'user/right/description/' + item.id, {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.userRightsDescriptionTitle = item.gruppe;
              this.userRightsDescription = data;
            }, 100);
          });
    }
  }

  activateUserRights(item) {
    if (this.modeValue === 'document') {
      this.http.put<any>(
          this.settings.restBaseUrl + 'user/documentRight/' + item.id + '/' + this.modelValue, {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            item.marked = data.message;
          });
    } else {
      this.http.put<any>(
          this.settings.restBaseUrl + 'user/right/' + item.id + '/' + this.modelValue, {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            item.marked = data.message;
          });
    }
  }

  deactivateUserRights(item) {
    if (this.modeValue === 'document') {
      this.http.delete<any>(
          this.settings.restBaseUrl + 'user/documentRight/' + item.id + '/' + this.modelValue, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            item.marked = null;
          });
    } else {
      this.http.delete<any>(
          this.settings.restBaseUrl + 'user/right/' + item.id + '/' + this.modelValue, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            item.marked = null;
          });
    }
  }

  testUserRights() {

  }

}
