import { Component, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-kanban-board',
  templateUrl: './kanban-board.component.html',
  styleUrls: ['./kanban-board.component.css']
})
export class KanbanBoardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
