import { Component, OnInit } from '@angular/core';
import {SettingsService} from '../../services/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  standalone: false,
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

    public httpOptions;
    public showCalendar = 0;
    public showAdministration = 0;
    public showUserMenu = false;
    public showTimetrackingQuickDialog: boolean;
    public showUserMenuOverlayBlack = false;
    public showUserMenuOverlay = false;
    public timeTrackingToday: [{type,timestamp,fk_user}];
    public currentTimetrackingStatus: any;
    public worktimeToday = '';
    public tooltipOptions = { 'placement': 'left', 'showDelay': 500 }

  constructor( public http: HttpClient, public router: Router, public settings: SettingsService ) {
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Tickets/Modul', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              this.showCalendar = data.message;
            }
        ));
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/administration/modul', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              this.showAdministration = data.message;
            }
        ));

      this.loadTimetrackingData();
  }

  ngOnInit(): void {
    // load menu icon rights
  }

  logout() {
    this.http.post<any>(this.settings.restBaseUrl + 'user/logout', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
                window.location.href = '../ui/index.html#/login';
            }
        ));
  }

  openAdmin() {
      const getUrl = window.location;
      let loadUrl = getUrl .protocol + '//' + getUrl.host + getUrl.pathname + '?session_id=' + this.settings.sessionId + '#/admin-base';
      window.open(loadUrl, '_blank');
  }

  openCalendar() {
    const url = '../calendar/calendar.php';
    this.router.navigate(['/core-frame' , encodeURIComponent(url + '?session_id=' + this.settings.sessionId )]);
  }


  openNew() {
    window.location.href = '../ticket/add_ticket.php?session_id=' + this.settings.sessionId ;
  }

    openUserProfil() {
        this.showUserMenu = false;
        this.router.navigate(['/system/user-profile']);
    }

    openHelp() {
        this.router.navigate(['/system/help' ]);
    }

    activateUserMenu() {
        this.showUserMenu = !this.showUserMenu;
        this.showUserMenuOverlayBlack = false;
        this.showUserMenuOverlay = true;
    }

    loadTimetrackingData() {
        this.http.get<[{type,timestamp,fk_user}]>(this.settings.restBaseUrl + 'timetracking/user/today', this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                if(data.length > 0) {
                    this.currentTimetrackingStatus = data[data.length-1].type;
                } else{
                    this.currentTimetrackingStatus = 1;
                }
                    this.timeTrackingToday = data;
                    let FullSeconds = 0;
                    let startTimestamp = 0;
                    for(let i= 0; i < data.length;i++) {
                        if(data[i].type == 0 || data[i].type == 3) {
                            startTimestamp = data[i].timestamp;
                        }

                        if(data[i].type == 1 || data[i].type == 2) {
                            FullSeconds += data[i].timestamp - startTimestamp;
                        }
                    }
                    if(startTimestamp>0 && ( this.currentTimetrackingStatus == 0 || this.currentTimetrackingStatus == 3 ) ) {
                        FullSeconds += (Math.floor(Date.now() / 1000)) - startTimestamp;
                    }

                    this.worktimeToday = '<B>' + Math.floor(FullSeconds / 3600 )+'</B> Stunden <B>'+Math.floor( (FullSeconds % 3600) / 60 )+'</B> Minuten';

                }
            ));
    }

    openUserTimetracking() {
        this.loadTimetrackingData();
        this.showUserMenu = false;
        this.showTimetrackingQuickDialog = true;
        this.showUserMenuOverlayBlack = true;
        this.showUserMenuOverlay = true;
    }

    closeAll() {
        this.showUserMenu = false;
        this.showTimetrackingQuickDialog = false;
        this.showUserMenuOverlayBlack = false;
        this.showUserMenuOverlay = false;
    }

    timeTrackingSend(type: number) {
        this.http.put<[]>(this.settings.restBaseUrl + 'timetracking', { type:type} ,this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            (data => {
                    this.loadTimetrackingData();
                }
            ));
    }
}
