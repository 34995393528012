import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from 'src/app/services/settings.service';

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  standalone: false,
  selector: 'product-group-sidebar',
  templateUrl: './product-group-sidebar.component.html',
  styleUrl: './product-group-sidebar.component.css',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ProductGroupSidebarComponent,
    multi: true
}]
})
export class ProductGroupSidebarComponent implements OnInit, ControlValueAccessor {

  // MODEL
  data: any;
  prevProductGroup = '0';

  @Input()  public ngModel: string;
  @Output() public ngModelChange = new EventEmitter<string>();
  @Output() public change  = new EventEmitter<Event>();
  
  constructor(public http: HttpClient, public settings: SettingsService) {
    this.initProductGroups();
  }

  modelChanged() { 
    this.ngModelChange.emit(this.ngModel); 
    this.change.emit(); 
  }
  
  initProductGroups() {
      this.http.post<{status, count, data}>(this.settings.restBaseUrl +  'erp/productgroup', {nested:'1',active:'1'}, this.settings.httpOptions )
      .subscribe({
        next: (data: { status, count, data }) => {
          this.data = data.data;
        },
        error: (error) => {
          console.log(error);
        }
      }
    );
  }

  ngOnInit() {
  }


  ngOnChanges(value) {
  }
  
  selectProductGroup(item: any) {
    this.ngModel = String(item.id);
    this.modelChanged();
  }

  toggleSubElements(element: any) {
    if(element.open == 1) {
      element.open = 0;
    } else {
      element.open = 1
    }
  }
  
  private recursivChangeCollapsState(data : any, state: String) {
    let reOpen = ''; // Variable in der die Idss rückwärts gesammelt werden wenn ein Element ausgewählt ist. Um dieses wieder zu öffnen.
    let found = '';
    let done = false;
    for(let n=0;n<data.length;n++) {
      if(data[n].id == this.ngModel) {
        // Ist das aktuelle Element das Ausgewählte?
        found = n + ";";
      }
      if(data[n].sub_count > 0) {
        data[n].open = state;
        reOpen += this.recursivChangeCollapsState(data[n].sub, state);
      }
      if(reOpen!='' && done == false) {
        // Wenn reOpen nicht leer ist und es das erst mal ist, hänge die aktuelle ID an.
        done = true; reOpen = reOpen + n + ";"; 
      }
    }
    if(found != '') { return found; } else { return reOpen; }
  }

  changeCollapsState(state: number) {
    let reOpen = this.recursivChangeCollapsState(this.data, state.toString());
    // Wenn eine Warengruppe ausgewählt ist. Dann den sntepsrechenden Ast wieder öffnen. Ids werden rückwärts in reOpen zurück gegben.
    if(reOpen!='') {
      let ids = reOpen.split(';');
      let tempData = this.data;
      for(let n=ids.length-2;n>=0;n--) {
        tempData[ids[n]].open=1;
        if(tempData[ids[n]] != undefined) { tempData = tempData[ids[n]].sub; }
      }
    }
  }

    // Folgende Methoden werden für Angular reactive Forms benötigt. Sonst wird eine Fehlermeldung ausgegeben.
    // Nicht implementiert, da wir diese nicht benutzen.
    writeValue(obj: any): void {}
    registerOnChange(fn: any): void {}
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}

}
