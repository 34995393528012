import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'prettyjson',
    standalone: false
})
export class PrettyjsonPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        return JSON.stringify(value, null, 2)
            .replace(/ /g, '') // note the usage of `/ /g` instead of `' '` in order to replace all occurences

            .replace(/"/g, '')
            .replace(/{\n/g, '<B> ')
            .replace(/{\n/g, '<B> ')
            .replace(/:/g, '</B>: ')
            .replace(/},\n/g, '</B>')
            .replace(/}/g, '')
            .replace(/,\n/g, ' <B> ')
            .replace(/\[/g, '')
            .replace(/\]/g, '')
            .replace(/\n/g, '<BR>')
            .replace(/<BR><BR><BR>/g, '')
            .replace(/<BR><BR>/g, '');
    }

}
