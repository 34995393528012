import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from '../../services/settings.service';
import {ModalService} from '../../_modal';

interface costCentreDataType {
    id:string;
    sort:string;
    number:string;
    name:string;
    group:string;
    group_company_bond:string;
    fullname:string;
    active:string;
    selected:boolean;
};

@Component({
  standalone: false,
  selector: 'app-cost-centre-selector',
  templateUrl: './cost-centre-selector.component.html',
  styleUrl: './cost-centre-selector.component.css',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CostCentreSelectorComponent),  // replace name as appropriate
            multi: true
        }
    ]
})
export class CostCentreSelectorComponent  implements OnInit, ControlValueAccessor, OnChanges  {

    public overlayId: string = Date.now() + '_' + Math.floor((Math.random() * 9999) + 1000);
    public OverlayActive = false;
    private currentSelectedOption: any = null;
    private currentSelected: any;
    public companyGroupLock = false;
    // Kann auch auf @Input() umgestellt werden und dann von Außen kommen. Oder per HTTP Rest Anfrage gefüllt werden
    @Input()  public tabindex: number;
    @Input()  public placeholder: string;
    @Input()  public company: string;
    @Input()  public ngModel: string;
    @Output() public ngModelChange = new EventEmitter<string>();
    @ViewChild( 'overlayContainer') overlayContainer: any;
    modelChanged() { this.ngModelChange.emit(this.ngModel); this.change.emit(); }
    @Output() public blur  = new EventEmitter<FocusEvent>();
    @Output() public change  = new EventEmitter<HashChangeEvent>();
    @Output() public focus  = new EventEmitter<FocusEvent>();
    @Output() public click  = new EventEmitter<MouseEvent>();
    @Input() disabled: boolean | string; // true oder null
    @Input() highlight: boolean; // Aktiviert die orange Markierung für aktiv bei der Suche oder nicht ausgefüllt bei einem Formular

    public overlayWidth: number = 200;

    public costCentreData:  costCentreDataType[];
    public costCentreDataFiltered:  costCentreDataType[];
    public searchActive: boolean = false;
    public ngModelDisplay: string;
    public selectedGroup: string = '';
    public allGroups: any;
    constructor(public http: HttpClient,
                public settings: SettingsService,
                public message: ModalService) {
    }

    ngOnInit(): void {
        this.loadCostCentreData();
        if(this.placeholder == undefined) { this.placeholder = ''; }
        if(this.ngModelDisplay == undefined) { this.ngModelDisplay = ''; }
    }


    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                if ( changes[propName].currentValue == changes[propName].previousValue) { return; } // nur wenn der change von außen kommt.
                if ( changes[propName].currentValue != '0' && changes[propName].currentValue != '' && changes[propName].currentValue != null && changes[propName].currentValue != undefined || changes[propName].currentValue >= 0) {
                    switch (propName) {
                        case 'company':
                            this.company=changes[propName].currentValue;
                            this.checkForBoundGroup();
                            break;
                        case 'ngModel':
                            if(changes[propName].currentValue < 1) {
                                this.currentSelected = null;
                                this.searchActive=false;
                                this.ngModelDisplay='';
                            }
                            break;
                    }
                }
            }
        }
    }
    blurEvent($event: FocusEvent) {
        if(this.searchActive) {
            this.selectCostCentre(this.costCentreDataFiltered[this.currentSelectedOption]);
            this.deactivateOverlay();
        }
        setTimeout(() => {
            this.searchActive = false;
            this.resetNgModelDisplay();
            this.deactivateOverlay();
            this.blur.emit($event);
        }, 100);
    }
    focusEvent($event: FocusEvent) { this.focus.emit($event); }
    clickEvent($event: MouseEvent) {
        $event.stopPropagation(); // Verhindert das doppelte Auslösen des Events.
        this.ngModelDisplay = '';
        this.click.emit($event);
    }
    keyupEvent(event: KeyboardEvent) {
        if (event.key == 'ArrowDown') {
            if(this.currentSelectedOption<0) {this.currentSelectedOption=0;}
            if(this.OverlayActive) {
                if(this.currentSelectedOption+1<this.costCentreDataFiltered.length) {
                    this.costCentreDataFiltered[this.currentSelectedOption++].selected = false;
                    this.costCentreDataFiltered[this.currentSelectedOption].selected = true;
                }
            } else {
                this.activateOverlay();
                this.costCentreDataFiltered[this.currentSelectedOption].selected = true;
            }
        } else if (event.key == 'ArrowUp') {
            if(this.OverlayActive) {
                if(this.currentSelectedOption>0) {
                   this.costCentreDataFiltered[this.currentSelectedOption--].selected = false;
                   this.costCentreDataFiltered[this.currentSelectedOption].selected = true;
                }
            }
        } else if (event.key == 'Enter') {
            if(this.ngModelDisplay=='') {
                this.resetCostCentre();
                setTimeout(() => { document.getElementById(this.overlayId+'CostCenterSelector').blur(); }, 100);
                return;
            }
            this.selectCostCentre(this.costCentreDataFiltered[this.currentSelectedOption]);
            this.deactivateOverlay();
        } else if (event.key == 'Escape') {
            this.deactivateOverlay();
        } else {
            this.filterData();
        }
    }

    deactivateOverlay() {
        this.OverlayActive = false;
        this.searchActive = false;
        this.resetNgModelDisplay();
    }

    activateOverlay() {
        this.overlayWidth = this.overlayContainer.nativeElement.offsetWidth - 4 + 100;
        this.OverlayActive = true;
        //this.costCentreData[this.currentSelectedOption].selected=false;
        this.currentSelectedOption=0;
    }

    // Folgende Methoden werden für Angular reactive Forms benötigt. Sonst wird eine Fehlermeldung ausgegeben.
    // Nicht implementiert, da wir diese nicht benutzen.
    writeValue(obj: any): void {}
    registerOnChange(fn: any): void {}
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}

    private loadCostCentreData() {
        this.http.post<{status:string,count:string,message:string,data:any}>( this.settings.restBaseUrl + 'erp/costcentre' , { active:1 }, this.settings.httpOptions )
            .subscribe({
                    next: (data) => {
                        this.costCentreData = data.data;
                        if(this.ngModel!='0') {
                            for(let i=0;i<this.costCentreData.length;i++) {
                                if(this.costCentreData[i].id==this.ngModel) { this.currentSelected = this.costCentreData[i]; this.selectedGroup = this.costCentreData[i].group;  this.resetNgModelDisplay(); break; }
                            }
                        }
                        if(this.company!='0') {this.checkForBoundGroup();}
                    },
                    error: (error) => {
                        console.log(error);
                    }
                }
            );
    }

    selectGroup(x: any) {
        if(this.companyGroupLock) { return;}
        if(x==null) {  this.selectedGroup=''; } else { this.selectedGroup = x.key; }
    }

    resetCostCentre() {
        if(!this.companyGroupLock) { this.selectedGroup=''; }
        this.currentSelected=null;
        this.ngModel='0';
        this.ngModelDisplay='';
        this.deactivateOverlay();
        this.modelChanged();
    }

    selectCostCentre(x: any) {
        this.currentSelected = x;
        this.ngModel=x.id;
        this.ngModelDisplay=x.name;
        this.modelChanged();
        this.deactivateOverlay();
    }

    private filterData() {
        this.activateOverlay();
        this.costCentreDataFiltered = null;
        this.costCentreDataFiltered = this.costCentreData.filter((item) => item.fullname.toString().toLowerCase().includes(this.ngModelDisplay));
        this.currentSelectedOption = 0;
        this.costCentreDataFiltered.map(item=>item.selected=false);
        this.costCentreDataFiltered[0].selected=true;
        this.searchActive = true;
    }

    private resetNgModelDisplay() {
        if(this.currentSelected == null) { return; }
        const index = this.costCentreData.indexOf(this.currentSelected);
        this.ngModelDisplay = this.costCentreData[index].name;
    }

    private checkForBoundGroup() {
        this.companyGroupLock = false;
        if(this.costCentreData==undefined || this.costCentreData.length<1) { return; }
        for(let i = 0;i < this.costCentreData.length;i++) {
            if(this.costCentreData[i].group_company_bond==this.company) {
                this.companyGroupLock = true;
                this.currentSelected = null;
                this.selectedGroup = this.costCentreData[i].group;
                this.searchActive=false;
                this.ngModel='0';
                this.ngModelDisplay='';
                break;
            }
        }
    }

    clearCoastCentre() {
        this.resetCostCentre();
        setTimeout(() => { document.getElementById(this.overlayId+'CostCenterSelector').blur(); }, 100);
        return;
    }
}
