import { Component, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: '[app-calendar-pickeapp-calendar-picker]',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.css']
})
export class CalendarPickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
