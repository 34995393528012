import { Component, OnInit } from '@angular/core';
import {TutorialService} from '../../services/tutorial.service';

@Component({
  standalone: false,
  selector: '[app-tutorial]',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {
  public posX: number;
  public posY: number;
  public width: number;
  public height: number;
  public active = false;
  public xPosLeftBox: number;
  public yPosBottomBox: number;
  public direction = 'left';
  public yPosTutorialBox: number;
  public tutorialContent: any;
  public currentTutorialContent: string;
  public activeHint: number;

  constructor(public tutorial: TutorialService) {
    tutorial.setDisplayComponent(this);
    this.posX = 100;
    this.posY = 200;
    this.width = 150;
    this.height = 200;
    this.valuesChanged();

    this.tutorialContent = [];
  }

  setContent(content) {
    this.tutorialContent = content;
  }

  ngOnInit(): void {
    this.activeHint = 0;
    this.displayContent();
  }

  nextHint() {
    this.activeHint ++;
    this.displayContent();
  }

  public displayContent() {
    if (this.tutorialContent.length<1) return;
    const item = this.tutorialContent[this.activeHint];
    setTimeout(() => {
      const element = document.getElementById(item.elementId);
      this.currentTutorialContent = item.content;
      const rect = element.getBoundingClientRect();
      this.posX = rect.left ;
      this.posY = rect.top ;
      this.width = element.clientWidth + 2;
      this.height = element.clientHeight + 2;
      this.valuesChanged();
      this.active = true;
    }, 200);
  }

  valuesChanged() {
    this.xPosLeftBox = Number(this.posX) + Number(this.width);
    this.yPosBottomBox = Number(this.posY) + Number(this.height);
    this.yPosTutorialBox = this.posY - 6 ;
  }

  closeTutorial() {
    this.activeHint = 0;
    this.active = false;
  }
}
