import {Component, Input, Output, OnInit, OnChanges, EventEmitter, ViewChildren, AfterViewInit, QueryList, ElementRef} from '@angular/core';

import { KeyValue } from '@angular/common';

@Component({
  standalone: false,
  selector: '[app-options]',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit, OnChanges {

  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');


  public overlayId: string;
  public OverlayActive = false;
  // MODEL
  public modelValue: any;
  @Output() modelChange: EventEmitter<any>;
  // tslint:disable-next-line:no-output-native
  @Output() change = new EventEmitter<boolean>();

  @Input()
  get model() {
    return this.modelValue;
  }
  set model(value: any) {
    this.ngOnChanges(value);
  }

  ngOnChanges(value) {
    this.modelValue = value;
  }

  constructor() {
    this.modelChange = new EventEmitter();
  }

  changeModel(value): void {
    this.modelValue = value;
    this.modelChange.emit(this.modelValue);
  }
  // ENDE MODEL


  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
  }

  activateOverlay(): void {
    this.OverlayActive = true;
    document.getElementById(this.overlayId).style.marginTop = '4px';
    document.getElementById(this.overlayId).style.marginLeft = '-70px';
    document.getElementById(this.overlayId).style.width = '165px';
    document.getElementById(this.overlayId).style.height = 'fit-content';
    document.getElementById(this.overlayId).style.visibility = 'visible';
  }

  deactivateOverlay(): void {
    this.OverlayActive = false;
    setTimeout(() => {
      document.getElementById(this.overlayId).style.visibility = 'hidden';
    }, 100);
  }

  toggleOverlay() {
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }

    setState(setTo) {
      for (const item of Object.values(this.modelValue.model.currentValue)) {
        // @ts-ignore
        const locked = item.locked;
        if (!locked)  {
          // @ts-ignore
          item.state = setTo;
        }
      }
      this.change.emit();
    }

  optionChanged() {
  }
}
