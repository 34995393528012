import { Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';
import { ModalService } from 'src/app/_modal';
import {SettingsService} from '../../services/settings.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'app-list-search-filter',
  templateUrl: './list-search-filter.component.html',
  styleUrls: ['./list-search-filter.component.css']
})
export class ListSearchFilterComponent implements OnInit {

  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');


  public overlayId: string;
  public OverlayActive = false;
  public filterList;

  @Input() model;
  @Input() filter;
  @Input() list;

  @Output() callback: EventEmitter<any> = new EventEmitter();

  newItemName: '';
  newItemMode = false;
  public deleteItem: any;

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService) {
  }

  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = +dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    this.initFilterList();
  }

  initFilterList() {
    this.http.post<{ status, count, data }>(
        this.settings.restBaseUrl + 'ui/listSearchFilter', {currentUser: true, list: this.list}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.filterList = data.data;
        });
  }

  activateOverlay(): void {
    this.OverlayActive = true;
    document.getElementById(this.overlayId).style.visibility = 'visible';
  }

  deactivateOverlay(): void {
    this.OverlayActive = false;
    setTimeout(() => {
      document.getElementById(this.overlayId).style.visibility = 'hidden';
    }, 100);
  }

  toggleOverlay() {
    if (this.OverlayActive === false) {
      this.activateOverlay();
    } else {
      this.deactivateOverlay();
    }
  }

  selectItem(item) {
    this.http.get<{ status, count, data }>(
        this.settings.restBaseUrl + 'ui/listSearchFilter/' + item.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          let contact = 0;
          let site = 0;
          let company = 0;
          this.deactivateOverlay();
          const listSearchSetting = JSON.parse(data.data.search_json_data);

          Object.keys(listSearchSetting).forEach(key => {
            if ( key === 'contact') { contact = listSearchSetting[key];
            } else if ( key === 'site') { site = listSearchSetting[key];
            } else if ( key === 'company') { company = listSearchSetting[key];
            } else { this.model[key] = listSearchSetting[key]; }
          });
          if ( contact > 0) { this.model.contact = contact;
            } else if ( company > 0) { this.model.site = 0; this.model.contact = 0; this.model.company = company;
          }
          if ( this.callback ) { this.callback.emit(); }

        });

  }

  addNewItem() {
      this.newItemMode = true;
      setTimeout(() => {
          document.getElementById('newListFavoriteName').focus();
      }, 100);

  }

  saveItem() {
    if (this.newItemName === '' || this.newItemName === undefined) {
      alert('ListSearchFilterNoNameErrorMessage');
      return;
    }
    const saveData = {};
    Object.keys(this.filter).forEach(key => {
      saveData[key] = this.model[key];
    });

    this.http.put<{ status, message, data }>(
        this.settings.restBaseUrl + 'ui/listSearchFilter', { name: this.newItemName, data: JSON.stringify(saveData), list: this.list }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            if ( data.status === 'Error' ) {
                if ( data.message === 'Name already exists') {
                    alert('Der von Ihnen eingegebene Name für den Filter existiert bereits. Bitte ändern Sie den Namen und Speichern sie erneut.');
                    return;
                }
            } else {
                this.newItemName = '';
                this.initFilterList();
                this.deactivateOverlay();
                this.newItemMode = false;
            }

        });
  }

  openDeleteMessage(item) {
    this.deleteItem = item;
    this.deactivateOverlay();
    this.message.open('ListSearchFilterDeleteMessage');
  }

  executeDeleteItem() {
    this.message.close('ListSearchFilterDeleteMessage');
    this.http.delete<{ status, count, data }>(
        this.settings.restBaseUrl + 'ui/listSearchFilter/' + this.deleteItem.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.deleteItem = null;
          this.initFilterList();
        });
  }

  closeDeleteItemMessage() {
    this.message.close('ListSearchFilterDeleteMessage');
    this.deleteItem = null;
  }

    checkForReturn(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.saveItem();
        }
    }
}
