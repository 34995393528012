import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  standalone: false,
    selector: 'ui-template',
    templateUrl: './ui-template.component.html',
    styleUrl: './ui-template.component.css',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: UiTemplateComponent,
        multi: true
    }]
})
export class UiTemplateComponent implements OnInit, ControlValueAccessor  {

    public overlayId: string = Date.now() + '_' + Math.floor((Math.random() * 9999) + 1000);
    public OverlayActive = false;
    private currentSelectedOption: number = null;
    // Kann auch auf @Input() umgestellt werden und dann von Außen kommen. Oder per HTTP Rest Anfrage gefüllt werden
    public overlayOptions = [
        {value:'Text 1', name:'Option 1', selected:false},
        {value:'Text 2', name:'Option 2', selected:false},
        {value:'Text 3', name:'Option 3', selected:false}
    ];
    @Input()  public tabindex: number;
    @Input()  public placeholder: string;
    @Input()  public ngModel: string;
    @Output() public ngModelChange = new EventEmitter<string>();
    @ViewChild( 'overlayContainer') overlayContainer: any;
    modelChanged() { this.ngModelChange.emit(this.ngModel); }
    @Output() public focus  = new EventEmitter<FocusEvent>();
    @Output() public blur  = new EventEmitter<FocusEvent>();
    @Output() public change  = new EventEmitter<Event>();
    @Output() public click  = new EventEmitter<MouseEvent>();
    @Input() disabled: boolean | string; // true oder null
    @Input() highlight: boolean; // Aktiviert die orange Markierung für aktiv bei der Suche oder nicht ausgefüllt bei einem Formular

    public overlayWidth: number = 200;

    ngOnInit(): void {
        if(this.placeholder == undefined) { this.placeholder = ''; }
    }
    focusEvent($event: FocusEvent) { this.focus.emit($event); }
    blurEvent($event: FocusEvent) { this.blur.emit($event); }
    changeEvent($event: FocusEvent) { this.change.emit($event); }

    clickEvent($event: MouseEvent) {
        $event.stopPropagation(); // Verhindert das doppelte Auslösen des Events.
        this.click.emit($event);
    }
    keyupEvent(event: KeyboardEvent) {
        if (event.key == 'ArrowDown') {
            if(this.OverlayActive) {
                if(this.currentSelectedOption+1<this.overlayOptions.length) {
                    this.overlayOptions[this.currentSelectedOption++].selected = false;
                    this.overlayOptions[this.currentSelectedOption].selected = true;
                }
            } else {
                this.activateOverlay();
                this.currentSelectedOption = 0;
                this.overlayOptions[this.currentSelectedOption].selected = true;
            }
        } else if (event.key == 'ArrowUp') {
            if(this.OverlayActive) {
                if(this.currentSelectedOption>0) {
                    this.overlayOptions[this.currentSelectedOption--].selected = false;
                    this.overlayOptions[this.currentSelectedOption].selected = true;
                }
            }
        } else if (event.key == 'Enter') {
            this.selectOverlayValue(this.overlayOptions[this.currentSelectedOption]);
            this.deactivateOverlay();
        } else if (event.key == 'Escape') {
            this.deactivateOverlay();
        } else {
            this.modelChanged();
        }
    }

    selectOverlayValue(option: { name:string, value:string, selected:boolean }) {
        this.ngModel = option.value;
        this.modelChanged();
        this.deactivateOverlay();
    }
    deactivateOverlay() { this.OverlayActive = false; }

    activateOverlay() {
       this.overlayWidth = this.overlayContainer.nativeElement.offsetWidth - 4;
       this.OverlayActive = true;
       this.overlayOptions[this.currentSelectedOption].selected=false;
       this.currentSelectedOption=0;
    }

    // Folgende Methoden werden für Angular reactive Forms benötigt. Sonst wird eine Fehlermeldung ausgegeben.
    // Nicht implementiert, da wir diese nicht benutzen.
    writeValue(obj: any): void {}
    registerOnChange(fn: any): void {}
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}

}
