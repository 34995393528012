import {Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';
import {SettingsService} from '../../services/settings.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: '[app-process-attachment-icon]',
  templateUrl: './process-attachment-icon.component.html',
  styleUrls: ['./process-attachment-icon.component.css']
})
export class ProcessAttachmentIconComponent implements OnInit {

  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');


  public overlayId: string;
  public OverlayActive = false;
  public attachments: any;
  @Input() process: {tid: ''};

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(public http: HttpClient, public settings: SettingsService) {
  }

  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
  }

  activateOverlay(): void {

    this.http.post<any[]>(this.settings.restBaseUrl + 'process/attachment' , { tid: this.process.tid }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.attachments = data;
          this.OverlayActive = true;
          document.getElementById(this.overlayId).style.marginLeft = '-180px';
          document.getElementById(this.overlayId).style.marginTop = '-50px';
          document.getElementById(this.overlayId).style.width = '220px';
          document.getElementById(this.overlayId).style.height = 'fit-content';
          document.getElementById(this.overlayId).style.visibility = 'visible';
        }
    );
  }

  deactivateOverlay(): void {
    this.OverlayActive = false;
    setTimeout(() => {
      document.getElementById(this.overlayId).style.visibility = 'hidden';
    }, 100);
  }

  toggleOverlay() {
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }

  viewFullscreen($event: MouseEvent, a: any ) {
      this.onClick.emit( { event: $event, item: a } );
      this.deactivateOverlay();
  }
}
