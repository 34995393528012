import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';

@Component({
  standalone: false,
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.css']
})
export class CompanyManagementComponent implements OnInit {
  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public currentSelected;
  public showNewItemForm: boolean;
  public expandedSearch = false;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public dropdownDataYears = [];
  public dropdownDataUser;
  public dropdownDataCategory: any[];
  public checkboxShiftStartElement: any;
  public checkboxData: any;
  public checkboxAllState: boolean;
  public checkboxActionDropdown = '-1';
  public checkboxActionDropdownData;
  public checkboxActionParameterDropdown = '-1';
  public checkboxActionParameterDropdownData;
  public httpOptions = { };
  public currentSelectedTab = 1;

  initCurrentSelected(): void {
    this.currentSelected = { id : '', name : '', nickname : '', contact : '', tel : '', mail_domains: '' };
  }

  initSearchParameter(): void {
    this.searchParameter = { loadFrom : 0, loadQuantity : this.searchQuantity , sort : 'name', active : '1', text: '', year : '', user : '', dateFrom : '', dateTo : '' };
  }

  constructor(public http: HttpClient,
              public settings: SettingsService) {
    this.initCurrentSelected();
    this.searchResultCount = 0;
    this.searchQuantity = 50;
    this.checkboxAllState = false;
    this.checkboxShiftStartElement = null;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > (currentYear - 20); i--) {
      this.dropdownDataYears.push(i);
    }

    this.http.post<any[]>(settings.restBaseUrl + 'user/list', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataUser = data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'template/category/suggest', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dropdownDataCategory = data;
        }
    );
  }

  ngOnInit() {
    this.search(false);
    document.getElementById('searchText').focus();
  }


  search(loadNextSearchResults: boolean): void {
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'contact/ownCompanies' , JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultCount === 0) { this.searchResultCount = data.count; }
          this.data = this.data.concat(data.data);
        }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  new(): void {
    this.initCurrentSelected();
    this.showNewItemForm = true;
    setTimeout(() => {
        document.getElementById('currentSelectedName').focus();
    }, 300);
  }

  save(): void {
    if (this.currentSelected.name == null || this.currentSelected.name === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
      return;
    } else if (this.currentSelected.nickname == null || this.currentSelected.nickname === '') {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie ein Kürzel ein.');
      return;
    }

    let endpointIdAdd = '';
    if (this.currentSelected.id > 0) {
      endpointIdAdd = '/' + this.currentSelected.id;
    }
    this.http.put<{message}>(
        this.settings.restBaseUrl + 'contact/ownCompanies/' + endpointIdAdd,
        JSON.stringify(this.currentSelected),
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.currentSelected.id < 1) {
            const newID = data.message;
            const siteData = {
                site_name: this.currentSelected.name,
                site_short_name: this.currentSelected.nickname,
                fk_company_id: newID,
                fk_contact_id: 0,
                type: 10
            };
            this.http.put<any[]>(
                this.settings.restBaseUrl + 'contact/company/sites', siteData, this.httpOptions)
                .pipe(map(data2 => data)).subscribe(
                data2 => {
                  this.search(false);
                  this.initCurrentSelected();
                  this.showNewItemForm = false;
                }
            );
          } else {
            this.search(false);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          }
        }
    );
  }


  edit($event: MouseEvent, item: any): void  {
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'contact/ownCompanies/' + item.id,
        this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelected = data;
          this.showNewItemForm = false;
          setTimeout(() => {
              document.getElementById('currentSelectedName').focus();
          }, 300);
        });
  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich deaktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'contact/ownCompanies/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich aktiviert werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'contact/ownCompanies/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initCurrentSelected();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initCurrentSelected();
          this.showNewItemForm = false;
        });
  }

  changeTabButton(tabId): void {
    this.currentSelectedTab = tabId;
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13) { this.search(false); }
  }
}
