import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../../services/settings.service';
import { Router } from '@angular/router';

@Component({
  standalone: false,
  selector: '[app-admin-menu]',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.css']
})
export class AdminMenuComponent implements OnInit {

  public httpOptions = { };
  public adminMenuEntries;
  public currentActiveRoute;
  public isAdmin = 0;
  public maintenance: string;

  constructor(public http: HttpClient, public settings: SettingsService, public router: Router) {

    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.http.post<{ menu,admin,maintenance }>(settings.restBaseUrl + 'ui/adminmenu', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.adminMenuEntries = data.menu;
          this.isAdmin = data.admin;
          this.maintenance = data.maintenance;
          this.currentActiveRoute = '#' + this.router.url;
        }
    );
  }

  ngOnInit() {
  }

  handleEntryClick(x: any) {

    if (x.route.substring(0, 2) === '#/') {
      this.router.navigate([x.route.replace('#', '')]);
    } else {
      document.location.href = x.url;
    }

  }

  changeSystemState() {
    if(this.maintenance==='1') {
      this.maintenance = '0';
      if(window.location.hostname.toLowerCase().includes("test")) {
        document.body.style.backgroundColor = '#25ab1b';
      } else {
        document.body.style.backgroundColor = '#497EDC';
      }
    } else {
      this.maintenance = '1';
      if(window.location.hostname.toLowerCase().includes("test")) {
        document.body.style.backgroundColor = '#ab1b31';
      } else {
        document.body.style.backgroundColor = '#ffc862';
      }
    }
    this.http.put<{ status,message }>(this.settings.restBaseUrl + 'system/maintenance', { maintenance : this.maintenance}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
        }
    );

  }
}
