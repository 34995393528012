import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService} from '../../_modal';

@Component({
  standalone: false,
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TimePickerComponent,
    multi: true
  }]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  @ViewChild( 'minuteSelectBox') overlayContainer: any;
  public overlayId: string;
  public hourStart = 6;
  public showHour: boolean = false;
  public showMinutes: boolean = false;
  public hourSelected = 0;
  public currentHour: string;
  public currentMinute: string;
  @Input() public ngModel:string;
  @Input() public tabindex: any;
  @Output() public ngModelChange = new EventEmitter<string>();
  @Output() public callback: EventEmitter<any> = new EventEmitter();
  private storedTime: string = '';
  public showMinutesTopPosition: number;


  keyDownEvent($event: KeyboardEvent) {
    if ($event.key === 'RETURN') { return; }
    if ($event.key === '+') {
      $event.preventDefault();
      const timeSplit = this.ngModel.split(':');
      let minute = Number(timeSplit[1]) + 15;
      let hour = Number(timeSplit[0]);
      if(minute>=60) { minute-= 60; hour++; if(hour>=24) { hour=0; } }
      let strHour: String;
      let strMinute: String;
      if(minute < 10) { strMinute = '0' + minute.toString(); } else { strMinute = minute.toString(); }
      if(hour < 10)   { strHour = '0' + hour.toString(); } else { strHour = hour.toString(); }
      this.ngModel = strHour + ':' + strMinute;
      return;
    }
    if($event.key === '-') {
      $event.preventDefault();
      const timeSplit = this.ngModel.split(':');
      let minute = Number(timeSplit[1]) - 15;
      let hour = Number(timeSplit[0]);
      if(minute<0) { minute += 60; hour--; if(hour<=0) { hour=23; } }
      let strHour: String;
      let strMinute: String;
      if(minute < 10) { strMinute = '0' + minute.toString(); } else { strMinute = minute.toString(); }
      if(hour < 10)   { strHour = '0' + hour.toString(); } else { strHour = hour.toString(); }
      this.ngModel = strHour + ':' + strMinute;
      return;
    }
    // Alle nicht Zahlen außer dem Doppelpunkt ausschließen
    if( $event.key != 'Tab' && $event.key != 'Backspace' && $event.key != ':' && Number.isNaN(Number($event.key) ) ) { $event.preventDefault(); return; }
    this.ngModelChange.emit(this.ngModel);
    return;
  }

  constructor(public message: ModalService) {
  }

    writeValue(value: any): void {
        this.ngModel = value;
    }
    registerOnChange(fn: any): void {
       // throw new Error('Method not implemented.');
    }
    registerOnTouched(fn: any): void {
        //throw new Error('Method not implemented.');
    }
    setDisabledState?(isDisabled: boolean): void {
        //throw new Error('Method not implemented.');
    }

  ngOnInit(): void {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
  }

  selectHour(hour: any, finish) {
    this.hourSelected = 1;
    hour = hour.toString();
    if(hour.length==1) { hour = '0' + hour; }
    this.ngModel = hour + ':00';
    this.separateTime();
    if(finish) {
      this.deactivateOverlays();
    } else {
      this.showHour = true;
      this.showMinutes = true;
      this.updateMinutePosition();
    }

    setTimeout(() => {
      this.hourSelected = 0; return;
    }, 150);
  }

  moveHour(direction: string) {
    setTimeout(() => {

      window.document.getElementById('timePicker' + this.overlayId).focus();
      this.hourSelected = 1;
      if( direction == 'up') {
        if(this.hourStart==6) { this.hourStart=0; }
        if(this.hourStart==11) { this.hourStart=6; }
      } else if( direction == 'down') {
        if(this.hourStart==6) { this.hourStart=11; }
        if(this.hourStart==0) { this.hourStart=6; }
      }
      this.separateTime();
      this.updateMinutePosition();
        setTimeout(() => {
          this.hourSelected = 0;
        }, 300);
    }, 50);
  }

  selectMinute(minutes: string) {
    this.hourSelected = 0;
    this.ngModel = this.ngModel.split(':')[0] + ':' + minutes;
    this.deactivateOverlays();
  }

  activateHourOverlay() {
    this.hourStart=6
    this.separateTime();
    if(Number(this.currentHour)<6) {
      this.moveHour('up');
    } else if(Number(this.currentHour)>18) {
      this.moveHour('down');
    }
    this.showHour = true;
    if(this.ngModel!='') {
      this.showMinutes = true;
    }
    this.updateMinutePosition();
  }

  separateTime() {
    const splitTime = this.ngModel.split(':');
    this.currentHour = splitTime[0];
    this.currentMinute = splitTime[1];
  }

  deactivateOverlays() {
    this.storedTime = '';
    setTimeout(() => {
      if(this.hourSelected == 1) { this.hourSelected = 0; return; }
      this.ngModel = this.addColonIfMissing(this.ngModel);
      if(this.checkTime(this.ngModel)) {
        this.ngModelChange.emit(this.ngModel);
          setTimeout(() => {
            if(this.callback) {
              this.callback.emit();
            }
          }, 200);
        }
      this.showHour = false;
      this.showMinutes = false;
    }, 100);
  }

  public addColonIfMissing(value:string) {
    let returnValue = value;
    if(value.includes(':')==false) {
      if(value.length==2) {  returnValue = value + ':00'; } // Eingab der Zeit in Stunden zweistellig ohne Doppelpunkt und Minuten, dann auf :00 erweitern.
      else if(value.length==4) {  returnValue = value.substring(0,2) + ':' + value.substring(2,4); } // Eingabe der Zeit vierstellig ohne Doppelpunkt.
    } else if(value.length==3 && value.slice(-1) == ':') {
      returnValue = value + '00'; // Minuten wurden gelöscht aber Doppelpunkt vorhanden. zwei Nullen für Minuten anhäng
    }
    return returnValue
  }

  public checkTime(value) {
    let error = 0;
    if(value.includes(':')==false) {
      error=1; }
    const timeParts = value.split(':');
    if(timeParts.length>2 ) { error=1; }
    if((timeParts[0]>-1 && timeParts[0]<24) == false) { error=1; }
    if((timeParts[1]>-1 && timeParts[1]<60) == false) { error=1; }
    if(error==1) {
      if(this.storedTime!='') { this.ngModel = this.storedTime; this.storedTime=''; }
      this.message.open('timeWarning');
      return false;
    }
    return true;
  }

  closeWarning() {
    this.message.close('timeWarning');
    document.getElementById('timePicker' + this.overlayId).focus();
  }

  manualEnter($event: KeyboardEvent) {
    if(this.storedTime == '' && Number($event.key)>=0 && this.ngModel.length>3) {
      this.storedTime = this.ngModel;
      this.ngModel = '' + $event.key;
      this.deactivateOverlays();
    }

  }
  private updateMinutePosition() {
    const topStartPos = -108;
    const hourBoxHeight = 15;
    this.showMinutesTopPosition = topStartPos + ( ( Number(this.currentHour)  - this.hourStart)  * hourBoxHeight );
  }

    modelChanged(event) {
      this.ngModelChange.emit(event.target.value);
      return;
    }
}
