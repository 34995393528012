import {Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {SettingsService} from '../../services/settings.service';

@Component({
  standalone: false,
  // tslint:disable-next-line:component-selector
  selector: '[app-dropdown]',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit, OnChanges  {

  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');

  public mode: string;
  public searchText: any;
  public overlayId: string;
  public OverlayActive = false;
  public dataEndpointUrl = '';
  public saveEndpointUrl = '';
  public httpOptions: { };
  public manualAdd = false;
  public inputClass = true;
  public textMode;
  public newGroup: any;
  public addMode: any;
  public tabIndexValue: number;
  public selectSuggestIndex = - 1;

  @Input() dropdownDataSource: any;
  @Input() mark: {  field,condition } ;
  @Input() alternateName: any;
  public filteredDropdownDataSource: any;
    // MODEL
  public modelValue = '';
  // @Output() onChange = new EventEmitter<boolean>();
  @Output() modelChange: EventEmitter<string>;
    public searchMode = false;

    @Input()
    get model() {
        return this.modelValue;
    }
    set model(value: string) {
        this.ngOnChanges(value);
    }

    ngOnChanges(value) {
        if ( typeof value === 'object' ) { return; }
        this.modelValue = value;
        this.updateSearchText(value);
    }

    changeModel(item): void {
        let value;
        if (this.textMode) {
            value = item[this.alternateName];
        } else {
            value = item.id;
        }
        this.modelValue = value;
        this.modelChange.emit(this.modelValue);
        this.updateSearchText(value);
    }

    // ENDE MODEL

  @Input()
  get dataEndpoint() {
    return this.dataEndpointUrl;
  }
  set dataEndpoint(value: string) {
    this.dataEndpointUrl = value;
  }

  @Input()
  get saveEndpoint() {
        return this.saveEndpointUrl;
  }
  set saveEndpoint(value: string) {
        this.saveEndpointUrl = value;
  }

  @Input()
  get activateTextMode() {
    return this.mode;
  }
  set activateTextMode(value: string) {
      if (value === 'true') {
          this.textMode = true;
      } else {
          this.textMode = false;
      }
  }

  @Input()
  get enableAdd() {
          return this.addMode;
      }
  set enableAdd(value: string) {
        if (value === 'true') {
        this.addMode = true;
        } else {
        this.addMode = false; }
    }

    @Input()
    get tabindex() {
        return this.tabIndexValue;
    }
    set tabindex(value) {
        this.tabIndexValue = value - 1;
    }

  constructor(public http: HttpClient, public settings: SettingsService) {
      this.textMode = false;
      this.modelChange = new EventEmitter();
      this.httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              token: settings.sessionId
          })
      };
  }

  ngOnInit() {
    this.searchText = '';

    if ( this.alternateName === undefined ) { this.alternateName = 'name'; }
    this.getDropdownData();
    if (this.textMode !== true && this.textMode !== false) { this.textMode = false; }
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    if ( this.textMode )  { setTimeout(() => { this.updateSearchText(this.modelValue); }, 150); }
  }

  search(event: KeyboardEvent): void {
      if (this.searchText.length < 1) { this.deactivateOverlay(); }
      this.filteredDropdownDataSource = this.dropdownDataSource.filter((item) => item[this.alternateName].toLowerCase().search(this.searchText.toLowerCase()) > -1 );
      this.clearSelectedIndex();
      if ( this.filteredDropdownDataSource !== undefined && this.filteredDropdownDataSource.length > 0) { this.activateOverlay(); }
  }

  getDropdownData(): void {
      if ( this.dataEndpointUrl === '' ) {

          this.filteredDropdownDataSource = this.dropdownDataSource;
          return;
      }
      this.http.post<any[]>(this.settings.restBaseUrl + this.dataEndpointUrl , {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              // @ts-ignore
              if (data.data !== undefined && data.data.length > 0 ) { this.dropdownDataSource = data.data; } else { this.dropdownDataSource = data; }
              this.filteredDropdownDataSource = this.dropdownDataSource.filter((item) => item.id > 0);
              if ( !this.textMode ) { this.updateSearchText(this.modelValue); }
              if ( this.alternateName === undefined || this.alternateName === '' ) { this.alternateName = 'name'; }
          }
      );
  }

  putItem(value: any): void {
      this.newGroup = { status: '', message: '' };
      this.http.put<any[]>(this.settings.restBaseUrl + this.saveEndpointUrl, { name: value } , this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              this.newGroup = data;
              this.modelValue = this.newGroup.message;
          }
      );
  }

  handleKeyboardInput(event) {
    if (event.which === 27 || event.key === 27) {
        if (this.OverlayActive) {
            this.selectSuggestIndex = - 1;
            this.deactivateOverlay();
            return;
        }
    }
    if (event.which === 38 || event.key === 38) {
        if (this.selectSuggestIndex === 0) { return; }
        if (this.selectSuggestIndex > - 1) { this.filteredDropdownDataSource[this.selectSuggestIndex].selected = false; }
        if (this.selectSuggestIndex === - 1) { this.selectSuggestIndex = this.filteredDropdownDataSource.length; }
        this.selectSuggestIndex--;
        this.filteredDropdownDataSource[this.selectSuggestIndex].selected = true;
    }
    if (event.which === 40 || event.key === 40) {
        if (this.selectSuggestIndex === this.dropdownDataSource.length - 1) { return; }
        if (this.selectSuggestIndex > - 1 ) {  this.filteredDropdownDataSource[this.selectSuggestIndex].selected = false; }
        this.selectSuggestIndex++;
        this.filteredDropdownDataSource[this.selectSuggestIndex].selected = true;
        return;
    }
    if (event.which === 13 || event.key === 13) {
        if (this.selectSuggestIndex > -1 ) {
            this.clickOnSuggestItem(this.filteredDropdownDataSource[this.selectSuggestIndex]);
            this.deactivateOverlay();
            document.getElementById( this.overlayId + 'Dropdown').blur();
            return;
        } else {
            if ( this.filteredDropdownDataSource.length < this.dropdownDataSource.length ) {
                this.clickOnSuggestItem(this.filteredDropdownDataSource[0]);
                this.deactivateOverlay();
                document.getElementById( this.overlayId + 'Dropdown').blur();
            }
        }
    }
  }

  clickOnSuggestItem(item) {
        if (this.textMode) {
            this.modelValue = item[this.alternateName];
            this.searchText = this.modelValue;
        } else {
            this.modelValue = item.id;
            let icon = '';
            if (item.icon !== undefined) {
                icon = '<img src="' + item.icon + '">';
            }
            if (item.img !== undefined) {
                icon = '<img src="' + item.img + '">';
            }
            this.searchText = icon + item[this.alternateName];
        }
    }

  handleClickEvent(item: any) {
        this.changeModel(item);
        this.deactivateOverlay();
  }

  endSearch() {
        this.searchMode = false;
        this.deactivateOverlay();
  }

  updateSearchText(value) {
      if ( value === -1 ) {
          this.searchText = '';
      }
      if (this.textMode === false) {
          if ( this.dropdownDataSource === undefined ) { return; }
          for (let i = 0; i <= this.dropdownDataSource.length; i++) {
              if (this.dropdownDataSource[i].id === value) {
                  const item = this.dropdownDataSource[i];
                  let icon = '';
                  if (item.icon !== undefined) {
                      icon = '<img style="vertical-align: text-bottom; margin-right: 2px;" src="' + item.icon + '">';
                  }
                  if (item.img !== undefined) {
                      icon = '<img style="vertical-align: text-bottom; margin-right: 2px;" src="' + item.img + '">';
                  }
                  this.searchText = icon + item[this.alternateName];
                  break;
              }
          }
      } else {
          this.searchText = value;
      }
  }

  clickOnOpenButton() {
      this.filteredDropdownDataSource = this.dropdownDataSource;
      this.activateOverlay();
  }

  activateOverlay(): void {
    this.OverlayActive = true;
    const mainWidthString = document.getElementById(this.overlayId + 'DropdownTable' ).parentElement.parentElement.style.width;
    let mainWidth = parseInt(mainWidthString.replace('px', '' ), 10);
    mainWidth = mainWidth - 10;
    document.getElementById(this.overlayId).style.width = mainWidth + 'px';
    document.getElementById(this.overlayId).style.visibility = 'visible';
  }

  deactivateOverlay(): void {
      this.OverlayActive = false;
      setTimeout(() => {
      document.getElementById(this.overlayId).style.visibility = 'hidden';
    }, 100);
      this.clearSelectedIndex();
      if (this.textMode) {
          this.modelValue = this.searchText;
      }
      this.modelChange.emit(this.modelValue);
  }

  toggleOverlay() {
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }

    toggleManualAdd() {
        this.manualAdd = !this.manualAdd;
        if (!this.manualAdd) {
            this.model = this.modelValue;
            this.modelChange.emit(this.modelValue);
        }
        if (!this.manualAdd || this.manualAdd && !this.textMode) {
            this.inputClass = true;
        }
        if (this.manualAdd && this.textMode) {
            this.inputClass = false;
        }
        if (!this.textMode && !this.addMode || this.textMode && this.addMode) {
            this.inputClass = true;
        }
    }

    handleModelChange($event) {
      if (this.manualAdd) {
            this.modelValue = this.searchText;
            this.modelChange.emit(this.modelValue);
    } else {
        this.search(this.searchText);
      }
  }

    cleatCurrentText() {
        this.searchText = '';
        this.clearSelectedIndex();
    }

    clearSelectedIndex() {
        this.selectSuggestIndex = -1;
        for (const item of this.filteredDropdownDataSource) {
            item.selected = 0;
        }
    }

    activateSearchMode() {
        this.searchText = '';
        this.searchMode = true;
        setTimeout(() => { document.getElementById( this.overlayId + 'Dropdown').focus(); }, 150);

    }
}
